<template>
  <div class="px-3">
    <!-- COUNTER:
    {{ $store.state.auth.counters["import_maps"] }} showDismissibleAlert:
    {{ showDismissibleAlert }} showDA: {{ showDA }} -->
    <!-- align-h="center" -->
    <b-row class="mt-1">
      <div class="col-md-12">
        <b-alert
          v-model="showDismissibleAlert"
          variant="danger"
          dismissible
          fade
        >
          ALCUNE FORME DI PAGAMENTO IMPORTATE NON SONO ANCORA MAPPATE, I VALORI
          DELLA CONTABILITA' POTREBBERO ESSERE INCOERENTI FINO ALLA MAPPATURA DI
          TUTTE LE FORME DI PAGAMENTO
        </b-alert>
      </div>
    </b-row>
    <b-row>
      <div class="col-md-8 p-0">
        <b-overlay center :show="eventsLoading" rounded="sm">
          <b-card class="calendar">
            <full-calendar
              ref="fullCalendar"
              :options="calendarOptions"
              class="fc"
            />
            <!-- :events="loadEvents" -->
          </b-card>
          <template #overlay>
            <div class="text-center">
              <base-icon name="loading" width="35" height="35" />
              <p id="cancel-label">Operazione in corso...</p>
            </div>
          </template>
        </b-overlay>
      </div>
      <div class="col-md-4 pt-3">
        <b-overlay center :show="tasksLoading" rounded="sm">
          <b-row>
            <b-col align="left">
              <h5 class="mb-3">Elenco attività</h5>
            </b-col>
            <b-col align="right">
              <b-button
                type="button"
                variant="primary"
                size="sm"
                @click="createTask(1)"
                title="Crea"
                v-if="canVerb('tasks', 'store')"
              >
                <b-icon icon="plus-circle" aria-hidden="true"></b-icon>
                Crea
              </b-button>
            </b-col>
          </b-row>
          <b-row class="ml-0 mr-0 mb-0" style="display: block; margin-top: 4px">
            <div class="task-wrapper">
              <!-- <b-card-group columns> -->
              <!-- :class="`mt-2 mr-2 ${getClassNames(task.status_task.value)}`" -->
              <b-card
                v-for="(task, index) in tasks"
                :key="index"
                :class="`card--task ${getClassNames(task)}`"
                :header="task.title"
                @click="viewTask(task)"
              >
                <b-card-text
                  >{{ trimTaskDescription(task.description) }}
                </b-card-text>
                <b-card-text
                  v-if="
                    task.expired_at !== null &&
                    (task.status_task.text === 'In lavorazione' ||
                      task.status_task.text === 'In attesa' ||
                      task.status_task.text === 'Da iniziare')
                  "
                  ><span style="font-weight: bold">Scadenza: </span>
                  <div
                    :style="{
                      color: setExpirationDateColor(
                        convertDateFormat(task.expired_at)
                      ),
                    }"
                  >
                    <span>{{ correctDateFormat(task.expired_at) }}</span
                    ><span
                      v-if="setExpirationDateColor(task.expired_at) == 'red'"
                      >{{ setExpiredLabel(task.expired_at) }}</span
                    >
                  </div>
                </b-card-text>
                <b-card-text class="mb-0" v-if="!isOwnTask(task)">
                  <span
                    style="font-style: italic"
                    v-html="ownerInfo(task)"
                  ></span>
                </b-card-text>
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                >
                  <b-card-text class="pill">
                    <span class="pill--inner">
                      <span class="pill--label">{{
                        task.status_task.text
                      }}</span>
                      <span class="pill--dot"></span> </span
                  ></b-card-text>
                  <b-button
                    v-if="
                      task.status_task.text === 'Completato' &&
                      isDeletable(task)
                    "
                    size="sm"
                    variant="lisaweb"
                    @click.stop="deleteEvent(task)"
                    class="mt-1 mr-1"
                    title="Elimina"
                  >
                    <b-icon icon="trash"></b-icon>
                  </b-button>
                </div>
              </b-card>
              <!-- </b-card-group> -->
              <!-- <div v-else class="d-flex justify-content-center mb-3">
                  <b-spinner label="Loading..." variant="warning"></b-spinner>
                </div> -->
            </div>
          </b-row>
          <template #overlay>
            <div class="text-center">
              <base-icon name="loading" width="35" height="35" />
              <p id="cancel-label">Operazione in corso...</p>
            </div>
          </template>
        </b-overlay>
      </div>
    </b-row>
    <b-row>
      <b-col align="left" v-if="canModule('module.HOME.LAPP') && false">
        <b-button-group v-b-toggle:collapse-1 class="my-2 filter-button-group">
          <span class="when-open">
            <b-icon icon="chevron-up" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="chevron-down" font-scale="1.5"></b-icon>
          </span>
          Esporta File Lisa4App
        </b-button-group>
      </b-col>
    </b-row>
    <b-collapse id="collapse-1" v-if="false">
      <b-row class="mb-5">
        <b-col align="left">
          <b-button
            class="btn-create mb-1"
            type="button"
            variant="primary"
            size="sm"
            :title="
              !hasLisaForAppPermission()
                ? 'Scarica File Unoone Polizze'
                : 'Funzione non abilitata'
            "
            v-b-toggle:collapse-1
            :disabled="hasLisaForAppPermission() || isDownloading"
            @click="onDownload('PTRAAUTO')"
          >
            <b-icon icon="clipboard-data" aria-hidden="true"></b-icon>
            Scarica File Unoone Polizze
          </b-button>
          <b-button
            class="btn-create mb-1"
            type="button"
            variant="primary"
            size="sm"
            :title="
              !hasLisaForAppPermission()
                ? 'Scarica File Unoone Dati Tecnici'
                : 'Funzione non abilitata'
            "
            v-b-toggle:collapse-1
            :disabled="hasLisaForAppPermission() || isDownloading"
            @click="onDownload('PTRAEXAU')"
          >
            <b-icon icon="tools" aria-hidden="true"></b-icon>
            Scarica File Unoone Dati Tecnici
          </b-button>
          <b-button
            class="btn-create mb-1"
            type="button"
            variant="primary"
            size="sm"
            v-b-toggle:collapse-1
            :title="
              !hasLisaForAppPermission()
                ? 'Scarica File Unoone Titoli'
                : 'Funzione non abilitata'
            "
            :disabled="hasLisaForAppPermission() || isDownloading"
            @click="onDownload('PTRAVITA')"
          >
            <b-icon icon="journals" aria-hidden="true"></b-icon>
            Scarica File Unoone Titoli
          </b-button>
        </b-col>
      </b-row>
    </b-collapse>
    <div v-if="formLoaded">
      <event-create-modal
        v-model="evt"
        :beFormCreate="beCreateForm['task']"
        :beRulesCreate="beRules['task']"
        @cancel="cancelEventCreation"
        @create="storeEvent"
      ></event-create-modal>
      <!-- :taskType="typ" -->

      <event-show-modal
        :selectInfo="inf"
        @delete="deleteEvent"
        @change="handleChangeClick"
      ></event-show-modal>
      <!-- :taskType="typ" -->
      <!-- :ref="changeModalRef" -->
      <event-change-modal
        v-model="evt"
        :beFormUpdate="beEditForm['task']"
        :beRulesUpdate="beRules['task']"
        @cancel="cancelEventChange"
        @update="putEvent"
      ></event-change-modal>
      <!-- :taskType="typ" -->
    </div>
    <!-- test shortcuts -->
    <!-- <b-row class="px-4">
      <div class="form-group col-md-2">
        <b-btn
          variant="lisaweb"
          class=""
          size="sm"
          @click="
            shortcut(
              'registries.edit',
              1,
              null,
              null,
              null,
              { /* fromShortcut: true */ },
              [
                {
                  text: 'Portafoglio',
                  path: '',
                  tab: '',
                  level: 0,
                  disabled: true,
                },
                {
                  text: 'Gestione Portafoglio',
                  path: 'module.PFOLIO',
                  tab: '',
                  level: 1,
                },
                {
                  text: 'Clienti',
                  path: 'module.PFOLIO',
                  tab: '#Registries',
                  level: 2,
                },
              ]
            )
          "
        >
          Mod. Cliente (id: 1)
        </b-btn>
      </div>
      <div class="form-group col-md-2">
        <b-btn
          variant="lisaweb"
          class=""
          size="sm"
          @click="
            shortcut('module.PFOLIO', null, '#Registries', 'filterRegistry', {
              'attributables.SURN': 'Fabbri',
            })
          "
        >
          Clienti (SURN: Fabbri)
        </b-btn>
      </div>
      <div class="form-group col-md-2">
        <b-btn
          variant="lisaweb"
          class=""
          size="sm"
          @click="shortcut('module.PFOLIO', null, '#Policies')"
        >
          Policies
        </b-btn>
      </div>
      <div class="form-group col-md-2">
        <b-btn
          variant="lisaweb"
          class=""
          size="sm"
          @click="
            shortcut(
              'module.PFOLIO',
              null,
              '#Registries',
              'filterRegistry',
              { id: 1 },
              {
                click: 'openModal',
                id: 1,
                modalName: 'modal',
                hash: '#General',
              }
            )
          "
        >
          Clienti tab Generale
        </b-btn>
      </div>
      <div class="form-group col-md-2">
        <b-btn
          variant="lisaweb"
          class=""
          size="sm"
          @click="
            shortcut(
              'module.PFOLIO',
              null,
              '#Policies',
              'filterPolicy',
              { id: 1 },
              {
                click: 'openModal',
                id: 1,
                modalName: 'modal',
                hash: '#Appointments',
              }
            )
          "
        >
          Polizze tab Appuntamenti
        </b-btn>
      </div>
      <div class="form-group col-md-2">
        <b-btn
          variant="lisaweb"
          class=""
          size="sm"
          @click="
            shortcut(
              'brokers.index0',
              null,
              null,
              'filterSalesmen',
              { id: 2 },
              {
                click: 'openModal',
                id: 2,
                modalName: 'modal',
                hash: '#Notes',
              }
            )
          "
        >
          Produttori tab Note
        </b-btn>
      </div>
      <div class="form-group col-md-2">
        <b-btn
          variant="lisaweb"
          class=""
          size="sm"
          @click="
            shortcut(
              'brokers.index1',
              null,
              null,
              'filterCooperaor',
              { id: 1 },
              {
                click: 'openModal',
                id: 1,
                modalName: 'modal',
                hash: '#Todo',
              }
            )
          "
        >
          Collaboratori tab Todo
        </b-btn>
      </div>
      <div class="form-group col-md-2">
        <b-btn
          variant="lisaweb"
          class=""
          size="sm"
          @click="shortcut('module.CONT.CONP', null, '#BrokerStatements')"
        >
          Contabilità produttori tab Storico rendiconti
        </b-btn>
      </div>
    </b-row> -->
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { mapGetters } from "vuex";
import moment from "moment";

moment.locale("it");

import FormMixin from "@/mixins/FormMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import CalendarEventsAndTasksMixin from "@/mixins/CalendarEventsAndTasksMixin";

export default {
  mixins: [ShortcutMixin, CalendarEventsAndTasksMixin, FormMixin],
  components: {
    BaseIcon,
    FullCalendar,
  },
  data() {
    return {
      isDownloading: false,
      showDismissibleAlert: false,
      calendarViewStartDate: null,
      calendarViewEndDate: null,
      lowerLimit: 6,
      upperLimit: 14,
      isExpired: false,
      // sampleTask: {
      //   id: 1,
      //   title: "event0",
      //   start: "2021-06-01T09:00:00",
      //   end: "2021-06-01T11:45:00",
      //   status_task: 2,
      //   description: "this is the description of event0",
      //   users: [1],
      //   owner_id: "luca@mail.com",
      //   taskable_id: "polizze",
      //   backgroundColor: "magenta",
      //   textColor: "white",
      // },
      formLoaded: false,
      formEditLoaded: false,
      tasksLoading: true,
      eventsLoading: true,
      repository: "task",
      tasks: [],
      form: {
        task: {
          id: null,
          title: null,
          description: null,
          task_type: null,
          status_task: null,
          expired_at: null,
          users: null,
          owner_id: null,
          //taskable: null, //nome della relazione
          //taskable_id: null, //array di ID di modelli (verosimilmente sempre 1 nelle ns. GUI)
          // need to handle date and time separated
          // started_at: null,
          // ended_at: null,
          start: null, // date
          end: null, // date
          startTime: null,
          endTime: null,
        },
      },
      beCreateForm: {}, // define this copy for co-existence of create an edit inside the same component
      beEditForm: {}, // define this copy for co-existence of create an edit inside the same component
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        height: 600,
        showNonCurrentDates: true,
        // nextDayThreshold: "00:00:00",
        locale: "it",
        firstDay: 1,
        slotMinTime: "00:00:00",
        slotMaxTime: "24:00:00",
        initialView: "timeGridDay",
        noEventsText: "Nessun appuntamento da visualizzare",
        allDayText: "tutto il giorno",
        headerToolbar: {
          // start: 'title', // will normally be on the left. if RTL, will be on the right
          // center: '',
          // end: 'today prev,next' // will normally be on the right. if RTL, will be on the left
          // start: 'title',
          // center: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
          // right: 'prevYear,prev,next,nextYear'
          start: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        },
        // titleFormat: { year: 'numeric', month: 'short', day: 'numeric' },
        buttonText: {
          today: "oggi",
          month: "mese",
          week: "settimana",
          day: "giorno",
          list: "lista",
        },
        eventTextColor: "#000",
        // eventBorderColor: "#F9991E",
        // dateClick: this.handleDateClick,
        events: [],
        editable: true,
        selectable: true,
        selectMirror: true,
        eventResizableFromStart: true,
        eventClassNames: this.handleEventClassName,
        // eventClassNames: function (arg) {
        // if (arg.event.extendedProps?.owner_id == this?.$store?.state?.auth?.user?.id) {
        //   return ["event-from-owner"];
        // } else {
        //   return ["event-from-other"];
        // }
        // },
        allDayMaintainDuration: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventDrop: this.customHandleEventDrop,
        eventAllow: this.handleEventAllow,
        eventResize: this.customHandleEventResize,
        //https://fullcalendar.io/docs/datesSet
        datesSet: this.handleMonthChange,
        // you can update a remote database when these fire
        // eventAdd: this.handleAddEvent,
        // eventRemove: this.handleRemoveEvent,
        // eventChange: this.handleChangeEvent,
      },
      // this prevents label errors on modals when trying to draw labels for non-defined-by-api fields (eg. start, end, startTime, endTime)
      extendedBeForm: {
        // required to merge the beForm with defaults (eg when fetchCreateForm or fetchEditForm doesn provide certain fields)
        start: {
          label: "Data inizio evento",
        },
        end: {
          label: "Data fine evento",
        },
        startTime: {
          label: "Ora inizio evento",
        },
        endTime: {
          label: "Ora fine evento",
        },
      },
      // a component implementing the taskable must define them (HERE JUST FOR DEBUG)
      //taskable_id: [1], // would be the resourceId of the show/edit view of the model
      //taskable: "insurancePolicies", // would be the relation
      taskable_id: null,
      taskable: null,
    };
  },
  props: {
    // msg: String,
  },
  beforeMount() {
    this.fetchCreateForm(this.repository)
      .then(() => {
        // initialize also the EditForm to prevent errors: real info later in eventChange
        this.beCreateForm[this.repository] = this.beEditForm[this.repository] =
          this.convertDataToCalendarEvents([this.beForm[this.repository]])[0];
      })
      .catch((error) => {
        let errMsg = this.$getErrorMessage(error);
        this.$showSnackbar({
          preset: "error",
          text: `${errMsg}`,
        });
      })
      .finally(() => {
        this.formLoaded = true;
      });
  },
  mounted() {
    this.showDismissibleAlert =
      this.$store.state.auth.counters["import_maps"] == 0 ? false : true;
    this.fetchEvents();
  },
  methods: {
    ...mapGetters("auth", {
      getLisaForAppsPermissions: "lisa_for_apps",
    }),
    ...mapGetters("auth", {
      getUsers: "users",
    }),
    customHandleEventDrop(draggedEvent) {
      this.eventsLoading = true;
      this.handleEventDrop(draggedEvent);
    },
    customHandleEventResize(eventResized) {
      this.eventsLoading = true;
      this.handleEventResize(eventResized);
    },
    hasLisaForAppPermission() {
      const permissions = this.getLisaForAppsPermissions();
      return Object.values(permissions).some(
        (permission) => permission?.can_extract?.value === "Y"
      )
        ? false
        : true;
    },
    isOwnTask(task) {
      return task?.owner_id == this?.$store?.state?.auth?.user?.id
        ? true
        : false;
    },
    ownerInfo(task) {
      return `Creato da: ${
        this.getUsers().find((user) => user.value === task?.owner_id)
          .username ?? "-"
      }`;
    },
    onDownload(code) {
      const Repo = RepositoryFactory.get("lisa_for_app");
      this.$showSnackbar({
        preset: "info",
        text: `Generazione in corso...`,
      });
      // let payload = {
      //   code,
      // };
      let payload = {
        extraction_type: null,
      };
      switch (code) {
        case "PTRAAUTO": // polizze
          payload.extraction_type = "polizze.txt";
          break;
        case "PTRAEXAU": // dati tecnici
          payload.extraction_type = "dettaglio_rca.txt";
          break;
        case "PTRAVITA": // titoli
          payload.extraction_type = "quietanze.txt";
          break;
        default:
          break;
      }
      this.isDownloading = true;
      Repo.report(payload)
        .then((response) => {
          this.$showSnackbar({
            preset: "success",
            text: "Download Iniziato",
          });
          const blob = new Blob([response.data], { type: response.data.type });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          // link.download = `${code}.${extension}`;
          link.download = `${payload.extraction_type}`;
          link.click();
          URL.revokeObjectURL(link.href);
          this.isDownloading = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isDownloading = false;
        });
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          this.$refs[this.tableRef].fetch();
          this.$showSnackbar({
            preset: "success",
            text: "Attività eliminata con successo",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    convertDateFormat(date) {
      return moment(date, "YYYY-MM-DD").format("YYYY-MM-DD");
    },
    correctDateFormat(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    setExpiredLabel(date) {
      const enteredDate = new Date(date).setHours(0, 0, 0, 0);
      const currentDate = new Date().setHours(0, 0, 0, 0);

      if (enteredDate < currentDate) {
        let showExpired = " (Scaduta)";
        return showExpired;
      }
      if (enteredDate == currentDate) {
        let showExpired = " (Ultimo giorno)";
        return showExpired;
      }
      return;
    },
    setExpirationDateColor(date) {
      let color = "";
      const enteredDate = new Date(date);
      const currentDate = new Date();

      if (enteredDate <= currentDate) {
        color = "red";
      } else if (currentDate > enteredDate.setDate(enteredDate.getDate() - 7)) {
        color = "orange";
      } else {
        color = "";
      }
      return color;
    },
    getDateMonth(date) {
      return moment(date, "YYYY-MM-DD").format("M");
    },
    calculateMonthRange(date) {
      // subtract lowerLimit days and add upperLimit days to the first and last day of the calendar view
      this.calendarViewStartDate = moment(date, "YYYY-MM-DD")
        .startOf("month")
        .subtract(this.lowerLimit, "d")
        .format("YYYY-MM-DD");
      this.calendarViewEndDate = moment(date, "YYYY-MM-DD")
        .endOf("month")
        .add(this.upperLimit, "d")
        .format("YYYY-MM-DD");
    },
    handleEventClassName(arg) {
      if (
        arg.event.extendedProps?.owner_id == this?.$store?.state?.auth?.user?.id
      ) {
        return ["event-from-owner"];
      } else {
        return ["event-from-other"];
      }
    },
    handleMonthChange(payload) {
      // call after the calendar’s date range has been initially set or changed in some way and the DOM has been updated.
      switch (payload.view.type) {
        case "dayGridMonth":
          if (
            // moment(moment(payload.start).format("YYYY-MM-DD")).isBefore(
            //   this.calendarViewStartDate
            // )
            moment(payload.start).isBefore(this.calendarViewStartDate)
          ) {
            // 1st day of month
            if (moment(payload.start).format("DD") == "01") {
              this.calculateMonthRange(
                moment(payload.start).format("YYYY-MM-DD")
              );
            } else {
              this.calculateMonthRange(
                moment(payload.start).add(1, "months").format("YYYY-MM-DD")
              );
            }
            this.fetchEvents(true, false);
            break;
          }
          if (
            // moment(
            //   moment(payload.end).subtract(1, "d").format("YYYY-MM-DD")
            // ).isAfter(this.calendarViewEndDate)
            moment(payload.end)
              .subtract(1, "d")
              .isAfter(this.calendarViewEndDate)
          ) {
            // not 28, 29, 30, 31 (last days of month)
            if (parseInt(moment(payload.end).format("DD")) <= this.upperLimit) {
              this.calculateMonthRange(
                moment(payload.end).subtract(1, "months").format("YYYY-MM-DD")
              );
            } else {
              this.calculateMonthRange(
                moment(payload.end).format("YYYY-MM-DD")
              );
            }
            this.fetchEvents(true, false);
          }
          break;
        case "timeGridDay":
        case "timeGridWeek":
        case "listWeek":
          if (
            // moment(moment(payload.start).format("YYYY-MM-DD")).isBefore(
            //   this.calendarViewStartDate
            // )
            moment(payload.start).isBefore(this.calendarViewStartDate)
          ) {
            this.calculateMonthRange(
              moment(payload.start).format("YYYY-MM-DD")
            );
            this.fetchEvents(true, false);
          }
          if (
            // moment(
            //   moment(payload.end).subtract(1, "d").format("YYYY-MM-DD")
            // ).isAfter(this.calendarViewEndDate)
            moment(payload.end)
              .subtract(1, "d")
              .isAfter(this.calendarViewEndDate)
          ) {
            this.calculateMonthRange(moment(payload.end).format("YYYY-MM-DD"));
            this.rangeChanged = true;
            this.fetchEvents();
          }
          break;
      }
    },
    async loadTasks() {
      const Repo = RepositoryFactory.get("task");
      let queryString = `perPage=0&byAttribute[task_type]=1`;
      return await Repo.index(queryString)
        .then((response) => {
          let data = response.data.data;
          let fetchedTasks = data
            .map((obj) => this.setClassNames(obj))
            .map((obj) => this.setAllDay(obj));
          return this.convertDataToCalendarEvents(fetchedTasks);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          return [];
        });
    },
    async loadEvents() {
      const Repo = RepositoryFactory.get("task");
      let queryString = `perPage=0&byAttribute[task_type]=0&byCalendar=${this.calendarViewStartDate},${this.calendarViewEndDate}`;
      return await Repo.index(queryString)
        .then((response) => {
          let data = response.data.data;
          let fetchedEvents = data
            .map((obj) => this.setClassNames(obj))
            .map((obj) => this.setDraggable(obj))
            .map((obj) => this.setAllDay(obj));
          // .map((obj) => this.toCalAllDay(obj));
          return this.convertDataToCalendarEvents(fetchedEvents);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          return [];
        });
    },
    fetchEvents(events = true, tasks = true) {
      let info = this.getCalendarViewActiveStart();
      if (!this.calendarViewEndDate && !this.calendarViewEndDate) {
        this.calculateMonthRange(`${info.year}-${info.month}-${info.day}`);
      }
      if (events) {
        this.eventsLoading = true;
        this.loadEvents().then((data) => {
          this.calendarOptions.events = data;
          this.eventsLoading = false;
          this.fromCalendar = true;
        });
      }
      if (tasks) {
        this.tasksLoading = true;
        this.loadTasks().then((data) => {
          this.tasks = data;
          if (this.tasks.length) {
            const statusOrder = [
              "In lavorazione",
              "In attesa",
              "Da iniziare",
              "Completato",
            ];
            this.tasks = this.tasks.sort((a, b) => {
              return (
                statusOrder.indexOf(a.status_task.text) -
                statusOrder.indexOf(b.status_task.text)
              );
            });
          }
          this.tasksLoading = false;
          this.fromCalendar = true;
        });
      }
    },
    trimTaskDescription(desc) {
      if (desc) {
        if (desc.length < 100) return desc;
        return `${desc.slice(0, 100)}...`;
      }
      return desc;
    },
    isDeletable(task) {
      return task.owner_id == this?.$store?.state?.auth?.user?.id
        ? true
        : false;
    },
  },
  computed: {
    showDA() {
      return this.$store.state.auth.counters["import_maps"] != 0;
    },
  },
};
</script>
<style scoped lang="scss">
.task {
  margin-right: 0px !important;
  margin-left: 0px !important;
  min-height: 240px;
}

:deep(.fc) {
  .fc-button-primary {
    background: #f3f0ea;
    border-color: #141413;
    color: grey;

    &:active {
      border-color: #fff;
    }
  }

  .fc-button-primary.fc-button-active {
    background: #f3f0ea;
    font-weight: bold;
    // border-radius: 0.25rem;
    &,
    &:not(:disabled) {
      color: #ed8208;
    }
  }

  .fc-button-primary:disabled {
    color: grey;
    border-color: grey;
    pointer-events: none;
    touch-action: none;
    cursor: default;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }

  .fc-button:not(:disabled) {
    color: black;
  }

  .fc-button:not(:disabled):active {
    color: white;
  }

  /* overall text color (day name, day number, date) */
  /* .fc-event-title, .fc-event-time, .fc-daygrid-day-number, .fc-col-header-cell-cushion {
    color: #2C3E50
  } */
  a {
    color: #2c3e50;

    .fc-event-main {
      &,
      * {
        color: white;
      }
    }
  }
}

/* event && task CSS */
/* :deep(.task-expiring) {
  border-color: red !important;
  border: 3px solid;
} */

/* task cards */
:deep(.card-title) {
  font-size: 0.9em;
}

:deep(.card-header) {
  font-size: 0.9em !important;
  /* font-shadow: 2px 2px #00ff00; */
}

:deep(.card-text) {
  font-size: 0.9em;
  /* font-shadow: 2px 2px #00ff00; */
}

.modal-title {
  width: 100%;

  & > span {
    color: #f3f0ea;
    font-weight: bold;
    padding: 0 0.5rem;
  }
}

ul {
  list-style-type: none;
}

.calendar {
  border-radius: 0px;
  border: 1px solid #f3f0ea;
  background-color: #f3f0ea;
}

.btn {
  text-transform: uppercase;
  font-weight: bold;
}

:deep(.fc-header-toolbar > .fc-toolbar-chunk:first-of-type),
:deep(.fc-header-toolbar > .fc-toolbar-chunk:last-of-type) {
  min-width: 250px;
}

:deep(.fc-view-harness-active .fc-col-header-cell-cushion) {
  margin-left: -30px;
}

:deep(.fc .fc-timegrid-axis-cushion) {
  text-align: right;
}

:deep(.fc-scrollgrid thead .fc-scroller) {
  overflow: hidden !important;
}

:deep(.fc-scrollgrid tbody tr:first-child .fc-scroller) {
  overflow: hidden !important;
}

:deep(.task-wrapper) {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 fixed columns with equal width */
  grid-auto-rows: minmax(
    100px,
    auto
  ); /* Minimum row height of 100px, can grow based on content */
  gap: 10px; /* Gap between grid items */
}

:deep(.task-wrapper > *) {
  cursor: pointer;
}

:deep(.task-wrapper .card-header) {
  padding-inline: 0.5rem;
  text-transform: none;
}

:deep(.task-wrapper .card-body) {
  padding: 0.5rem !important;
}

:deep(.card--task .card-body) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

:deep(.pill) {
  font-size: 10px;
  line-height: 19px;
  margin: 4px 0 0;
  max-width: max-content;
}

:deep(.pill--inner) {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 22px;
  padding: 2px 8px 3px 10px;
  box-sizing: border-box;
  border-radius: 100vmax;
  margin: 0;
}

:deep(.pill--label) {
  display: block;
  color: black;
  line-height: 15px;
  min-width: 0;
  text-align: right;
  font-weight: 600;
}

:deep(.pill--dot) {
  position: relative !important;
  top: 1px;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

:deep(.task-status-0 .pill--inner) {
  background: rgba(26, 26, 26, 0.0705882353);
}

:deep(.task-status-0 .pill--dot) {
  background: #999;
}

:deep(.task-status-1 .pill--inner) {
  background: #009fe324;
}

:deep(.task-status-1 .pill--dot) {
  background: #009fe3;
}

:deep(.task-status-2 .pill--inner) {
  background: rgba(255, 214, 164, 0.7803921569);
}

:deep(.task-status-2 .pill--dot) {
  background: rgba(253, 126, 20, 0.768627451);
}

:deep(.task-status-3 .pill--inner) {
  background: rgba(146, 254, 194, 0.7215686275);
}

:deep(.task-status-3 .pill--dot) {
  background: rgba(40, 167, 69, 0.7019607843);
}

:deep(.calendar .event-from-other) {
  background: #6f6259;
  border: 1px solid #6f6259;
}

:deep(.calendar .event-from-owner) {
  background: #0658a9;
  border: 1px solid #0658a9;
}
</style>
